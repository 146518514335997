import axios from '../axios'

const handleLoginApi = (email, password) => {

     return axios.post('/api/login', { email, password })
}
const getAllUsers = (inputId) => {
     return axios.get(`/api/get-all-users?id=${inputId}`)
}
const createNewUserService = (data) => {
     return axios.post('/api/creat-new-user', data)
}
const deleteUserService = (userId) => {
     return axios.delete(`/api/delete-user`, {
          params: {
               id: userId
          }
     })
}
const editUserService = (user) => {
     return axios.put('/api/edit-user', user)
}
const getAllCodeService = (inputType) => {
     return axios.get(`/api/allcode?type=${inputType}`)
}
const getTopDoctorHomeService = (limit) => {
     return axios.get(`/api/top-doctor-home?limit=${limit}`)
}
const getAllDoctors = () => {
     return axios.get(`/api/getAllDoctors`)
}
const saveDetailDoctorService = (data) => {
     return axios.post('/api/save-info-doctors', data)
}
const getDetailInforDoctor = (inputId) => {
     return axios.get(`/api/get-detail-doctor-by-id?id=${inputId}`)

}
const saveBulkScheduleDoctor = (data) => {
     return axios.post('api/bulk-create-schedule', data)
}
const getScheduleDoctorByDate = (doctorId, date) => {
     return axios.get(`/api/get-schedule-doctor-by-date?doctorId=${doctorId}&date=${date}`)
}
const getExtraInfoDoctorById = (doctorId) => {
     return axios.get(`/api/get-extra-infor-doctor-by-id?doctorId=${doctorId}`)
}
const getProfileDoctorById = (doctorId) => {
     return axios.get(`/api/get-profile-doctor-by-id?doctorId=${doctorId}`)
}
const postPatientBookAppointment = (data) => {
     return axios.post('/api/patient-book-appointment', data)
}
const postVerifyBookAppointment = (data) => {
     return axios.post('/api/verify-book-appointment', data)
}
const createNewSpecialty = (data) => {
     return axios.post('/api/creat-new-specialty', data)
}
const getAllSpecialty = () => {
     return axios.get(`/api/get-specialty`)
}
const getAllDetailSpecialtyById = (data)=>{
     return axios.get(`/api/get-detail-specialty-by-id?id=${data.id}&location=${data.location}`)
}
const getAllDetailClinicById = (data)=>{
     return axios.get(`/api/get-detail-clinic-by-id?id=${data.id}`)
}
const createNewClinic = (data) => {
     return axios.post('/api/creat-new-clinic', data)
}
const getAllClinic = () => {
     return axios.get(`/api/get-clinic`)
}
const getAllPatientForDoctor = (data)=>{
     return axios.get(`/api/get-list-patient-for-doctor?doctorId=${data.doctorId}&date=${data.date}`)
}
const postSendRemedy = (data) => {
     return axios.post('/api/send-remedy', data)
}
export {
     handleLoginApi, getAllUsers,
     createNewUserService, deleteUserService,
     editUserService, getAllCodeService,
     getTopDoctorHomeService,
     getAllDoctors,
     saveDetailDoctorService,
     getDetailInforDoctor,
     saveBulkScheduleDoctor,
     getScheduleDoctorByDate,
     getExtraInfoDoctorById,
     getProfileDoctorById,
     postPatientBookAppointment,
     postVerifyBookAppointment,
     createNewSpecialty,
     getAllSpecialty,
     getAllDetailSpecialtyById,
     createNewClinic,
     getAllClinic,
     getAllDetailClinicById,
     getAllPatientForDoctor,
     postSendRemedy
}